<template>
  <v-tabs
    v-model="selectedTab"
    grow
    show-arrows
    class="elevate-tab-headers"
    color="accent"
  >
    <v-tab v-for="tab in tabs" :key="tab.name">
      {{ tab.text }}
    </v-tab>
    <v-tabs-slider color="accent"></v-tabs-slider>
    <v-tabs-items v-model="selectedTab" class="elevation-0 transparent">
      <v-tab-item v-for="tab in tabs" :key="tab.name">
        <current-month-tab
          v-if="tab.name === 'current_month'"
          :filters="filters"
        ></current-month-tab>
        <monthly-tab
          v-else-if="tab.name === 'monthly'"
          :filters="filters"
        ></monthly-tab>
        <v-alert v-else :value="true" type="warning"
          >Under construction</v-alert
        >
      </v-tab-item>
    </v-tabs-items>
  </v-tabs>
</template>
<script>
import CurrentMonthTab from "../../../common/dashboard/CurrentMonthTab.vue";
import MonthlyTab from "../../../common/dashboard/MonthlyTab.vue";

export default {
  components: { MonthlyTab, CurrentMonthTab },
  props: {
    filters: {
      required: false,
      default: {}
    }
  },
  data: () => ({
    selectedTab: null,
    tabs: [
      { name: "current_month", text: "This month" },
      { name: "monthly", text: "Monthly" }
    ]
  })
};
</script>
