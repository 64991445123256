<template>
  <div>
    <div class="subtitle-1 light-grey mb-2">
      Summary view of your data
    </div>
    <v-flex xs12 md3>
      <BusinessSelect
        v-model="filters.business_uuid"
        :return-object="false"
        solo
        hide-details
      />
    </v-flex>
    <br />
    <agency-dashboard-tabs :filters="filters"></agency-dashboard-tabs>
  </div>
</template>
<script>
import AgencyDashboardTabs from "../Tabs/BusinessTabs/AgencyDashboardTabs";
import BusinessSelect from "../../selects/BusinessSelect";
import search_filters_url from "@/helpers/mixins/search_filters_url";

export default {
  mixins: [search_filters_url],
  components: {
    BusinessSelect,
    AgencyDashboardTabs
  },
  data: () => ({
    filters: {
      business_uuid: null
    }
  }),
  watch: {
    filters: {
      deep: true,
      handler(newVal) {
        this.setUrlSearchFilters(newVal);
      }
    }
  },
  created() {
    this.filters = this.getUrlSearchFilters();
  }
};
</script>
